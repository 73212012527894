import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
// ...GatsbyImageSharpFluid
const About = () => {
  return <div>
    <p>#SoyDeveloper o como me gusta decir Dadveloper ya que actualmente divido mi tiempo entre el desarrollo de software y el cuidado de mi hija.</p>
    <p>Mi herramienta principal es GeneXus y poco a poco me estoy acercando a otros lenguajes.</p>
    <p>Me encanta probar nuevas tecnologías y descubrir formas novedosas de hacer las cosas.</p>
    <p>Mi meta a 5 años es convertirme en SRE.</p>
  </div>
}

export default About
